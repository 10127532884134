import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import { StoryBannerStyles } from "./StoryBannerStyles"

const StoryBanner = ({ children, title, subTitle, enquire }) => {

  return (
    <>
      <StoryBannerStyles>
        {children ? (
          children
        ) : (
          <StaticImage
            className="banner__image"
            imgClassName="banner__image--content"
            src="../../images/we_serve_on_BG.jpg"
            alt="Banner image: Lions - We Serve."
            layout="fullWidth"
            placeholder="blurred"
          />
        )}

        <div className="container">
          <div className="banner__content">
            {title && (
              <h1>
                {title}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h1>
            )}
            {subTitle && <h2>{subTitle}</h2>}           
            {<Button text="Read the Story" as={Link} to="#topContent" />}           
          </div>
        </div>
        </StoryBannerStyles>
      <span id="topContent"></span>
    </>
  )
}

export default StoryBanner
