import React, { useState } from "react"
import { ClubContactStyles } from "./ClubContactStyles"
import { MdExpandMore as Chevron } from "react-icons/md"
import Contact from "../Contact/Contact"
import {
  FaFacebookSquare as Facebook,
  FaTwitterSquare as Twitter,
  FaInstagram as Instagram,  
  FaYoutubeSquare as YouTube,
  FaPinterestSquare as Pinterest,
  FaSnapchatSquare as Snapchat,
  FaTiktok as Tiktok
} from "react-icons/fa"

const ClubContact = ({ lionsClub, slug }) => {
  const [showInfo, setInfo] = useState(true)

  const toggleInfo = () => {
    setInfo(showInfo => !showInfo)
  }
  return (
    <ClubContactStyles className={showInfo ? `${"faq-open"}` : `${"faq-closed"}`}>
      <button className="question" onClick={toggleInfo}>
        About the {lionsClub.clubTitle}
        <div className="trigger">
          <Chevron />
        </div>
      </button>      
      {showInfo && <>
                      <div className="answer">{lionsClub.clubBio.clubBio}<br/>
                          <br/>
                          { (lionsClub.socialMediaUrl && lionsClub.socialMediaIcon) &&
                        <>
                    <span >              
                  { (lionsClub.socialMediaIcon==="Facebook") && <Facebook className="social-icon"/> }
                  { (lionsClub.socialMediaIcon==="Twitter") && <Twitter className="social-icon"/> }
                  { (lionsClub.socialMediaIcon==="Instagram") && <Instagram className="social-icon"/> }
                  { (lionsClub.socialMediaIcon==="YouTube") && <YouTube className="social-icon"/> }
                  { (lionsClub.socialMediaIcon==="Pinterest") && <Pinterest className="social-icon"/> }
                  { (lionsClub.socialMediaIcon==="Snapchat") && <Snapchat className="social-icon"/> }
                  { (lionsClub.socialMediaIcon==="Tiktok") && <Tiktok className="social-icon"/> }
                   &nbsp;
                   <a href={lionsClub.socialMediaUrl}
                    target="_blank"
                    rel="nofollow noreferrer noopener"                    
                  >Follow us on {lionsClub.socialMediaIcon}                  
                  </a></span><br/><br/></>}
                          <b>Get In Touch with the Club:</b>
                          <div className="contact-border">
                          <Contact slug={slug}/>
                          </div>
                  </div></>}      
    </ClubContactStyles>

  )
}

export default ClubContact
