import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import ClubContact from "../components/ClubContact/ClubContact"
import Seo from "../components/SEO"
import StoryBanner from "../components/StoryBanner/StoryBanner"
import ContentfulRichText from "../components/ContentfulRichText"

const StoryTemplateStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: var(--sectionPadding) var(--borderSpacing);
  background-color: #1f2545;

  .column {
    flex: 0 0 100%;

    @media (min-width: 768px) {
      flex-basis: 50%;

      &:nth-child(1) {
        padding-right: 20px;
      }

      &:nth-child(2) {
        padding-left: 20px;
      }

      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > * {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`

const storyTemplate = ({ data }) => {
  const { slug, name, excerpt, story, images, lionsClub } = data.lionsstory

  const [mainImage] = images
  const image = getImage(mainImage)
  const storyKeywords = name + "," + lionsClub.clubTitle + "," + lionsClub.clubTitle.split(" ").join(",")
  
  return (
    <>
      <Seo pageTitle={name} 
          pageDescription={excerpt} 
          canonicalUri={`/lionsstory/${slug}`}
          pageKeywords = {storyKeywords}
          pageImageUrl = {images[0].url}
          pageImageWidth = {images[0].width}
          pageImageHeight = {images[0].height}
      />
      <StoryBanner
        title={name}
        subTitle={excerpt}        
      >
        <GatsbyImage
          className="banner__image"
          image={image}
          alt="Banner Image"
        />
      </StoryBanner>
      <StoryTemplateStyles>
        <div className="column"><ContentfulRichText richText={story} /></div>
        <div className="column">
             <ClubContact
                slug={slug}
                lionsClub={lionsClub}              
              />
        </div>
      </StoryTemplateStyles>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    lionsstory: contentfulLionsStory(slug: { eq: $slug }) {
      contentful_id
      name
      slug
      excerpt
      story {
        raw
        references {
          contentful_id
          title
          description
          gatsbyImageData(width: 1000, formats: [AUTO, WEBP])
          __typename
        }         
      }
      images {
        gatsbyImageData(width: 2000, formats: [AUTO, WEBP])
        url
        width
        height
      }
      lionsClub {
        socialMediaIcon
        socialMediaUrl
        clubTitle
        clubBio {
          clubBio
        }        
      }
    }
  }
`

export default storyTemplate
